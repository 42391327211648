.input {
  margin: 2.5px 5px;
  border-bottom-width: 1px;
}
.checkInput {
  color: white;
  border-radius: 5px;
  /* text-align: center; */
  margin: 2.5px 5px;
  border-bottom-width: 1px;
}
.span {
  margin: 0px 5px;
  background-color: yellow;
}
.b {
  margin: 0px 5px;
}

.video-container {
  overflow: hidden;
  position: relative;
  width:60%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centeredRow {
  justify-content: center;
}
