.img {
  cursor: pointer;
  transition: 0.5s;
  width: 100%;
  height: auto;
  /* max-height: calc(100vh - 56px - 159px - 68px); */
}
.container{
  flex: 1;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  /* transition:0.2s; */
  height: auto;
  width: 74vh;
}
.imgWrapper {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.imgWrapperZoomIn {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.scrollView {
  width: calc(100% - 120px);
  height: auto;
  display: flex;
  align-self: center;
  flex-direction: column;
}
.imgScrollView {
  width: 100%;
  height: auto;
}
@media (max-height: 920px){
 .container {
   width: 76vh;
 }
}





